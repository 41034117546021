import React, {useState} from 'react';
import './scss/styles.scss'
import ColourPicker from './components/colourPicker';
import RangeSlider from "./components/rangeSlider";
import CustomFont from "./components/customFont";
import Footer from "./components/Footer"
import logo from "./components/images/logo.png"
import Panel from "./components/images/ChatStyler-Panel.png"
import AitumLogo from "./components/images/AitumLogo.png"
import AitumSnapshot from "./components/images/AitumSnapshot.png"
import NumberInput from "./components/numberInput";

function App() {
    //set output for user properties in code
    const [timerDirection, setTimerDirection] = useState('CountDown')
    const [timerLoop, setTimerLoop] = useState('false');
    const [timerFont, setTimerFont] = useState('Bebas Neue');
    const [timerBackground, setTimerBackground] = useState('none');
    const [timerColour, setTimerColour] = useState('#ff0000');
    const [timerSize, setTimerSize] = useState('16');
    const [timerWeight, setTimerWeight] = useState('400');
    const [timerBorderRadius, setTimerBorderRadius] = useState('0');
    const [timerHours, setTimerHours] = useState(0);
    const [timerMinutes, setTimerMinutes] = useState(10);
    const [timerSeconds, setTimerSeconds] = useState(30);

    let urlColour = timerColour.replace('#', '')
    let urlBackground = timerBackground.replace('#', '')

    let convertedHours = timerHours * 3600;
    let convertedMinutes = timerMinutes * 60;
    let totalTime = convertedHours + convertedMinutes + timerSeconds;

    const timerFontCode = timerFont.replace(/ /g, '+');

    const [copiedURL, setCopiedURL] = useState(false);

    const copyURL = () => {
        let timerURL: string = document.getElementById('timerURL')?.innerText as string;
        navigator.clipboard.writeText(timerURL)

        setCopiedURL(true);
        setTimeout(() => {
            setCopiedURL(false);
        }, 2000);
    }

    function directionSet() {
        const directionCheckbox = document.getElementById("directionOutput") as HTMLInputElement;

        if (directionCheckbox.checked) {
            setTimerDirection('CountUp');
        } else {
            setTimerDirection('CountDown');
        }

        return (
          timerDirection
        )
    }

    function loopSet() {
        const loopCheckbox = document.getElementById("loopOutput") as HTMLInputElement;
        console.log(timerLoop);
        if (loopCheckbox.checked) {
            setTimerLoop('true');
        } else {
            setTimerLoop('false');
        }

        return (
          timerDirection
        )
    }

    return (
        <>
            <header className="l-intro">
                <div className="container">
                    <h1>Browser Timer</h1>

                    <p className='preamble'>
                        Welcome to the browser timer, create the timer that <strong>YOU</strong> want.
                    </p>
                    <p>
                        Customise your timer below, the background colour fills the whole background of the timer. Once done, copy the URL by clicking on it and paste it into your Browser Source.
                    </p>
                </div>
            </header>
            <div className="container">
                <div className="main">
                    <div className="row">
                        <div className="l-left">
                            <div className="e-form">
                                <h2>Customise Timer</h2>

                                <div className="form-row">

                                    <div className="timeset">
                                        <NumberInput  propertyName="Hours" maxAmount={1000}
                                                    minAmount={0} value={timerHours} numberProperty={setTimerHours}/>
                                        <NumberInput  propertyName="Minutes" maxAmount={60}
                                                    minAmount={0} value={timerMinutes} numberProperty={setTimerMinutes}/>
                                        <NumberInput  propertyName="Seconds" maxAmount={60}
                                                    minAmount={0} value={timerSeconds} numberProperty={setTimerSeconds}/>
                                    </div>
                                    <div className='toggle'>
                                        <input type="checkbox" id="directionOutput" onChange={directionSet}/>
                                        <label htmlFor="directionOutput">Count Up</label>
                                    </div><div className='toggle'>
                                        <input type="checkbox" id="loopOutput" onChange={loopSet}/>
                                        <label htmlFor="loopOutput">Loop Timer</label>
                                    </div>
                                    <CustomFont propertyName="Font" cssProperty='--userFont'
                                                fontProperty={setTimerFont}
                                                fontWeight={timerWeight}/>
                                    <ColourPicker propertyName="Color" cssProperty='--userColor' default='#FFFFFF'
                                                colourProperty={setTimerColour}/>
                                    <ColourPicker propertyName="Background" cssProperty='--userBackground'
                                                default='transparent' colourProperty={setTimerBackground}/>
                                    <RangeSlider propertyName="Font Size" cssProperty="--userFontSize" maxAmount={320}
                                                minAmount={12} steps={1} value='250' rangeProperty={setTimerSize}/>
                                    <RangeSlider propertyName="Border Radius" cssProperty="--userBorderRadius"
                                                maxAmount={80}
                                                minAmount={0} steps={1} value='0' rangeProperty={setTimerBorderRadius}/>

                                    <div className="copyurl">
                                        URL
                                        <div className='innerurl' onClick={copyURL} >
                                            <span id='timerURL'>https://timer.colinhorn.co.uk/timer?time={totalTime}&colour={urlColour}&size={timerSize}&background={urlBackground}&radius={timerBorderRadius}&font={timerFontCode}&direction={timerDirection}&loop={timerLoop}</span>
                                            <button className='btn copybutton' title='Copy'>
                                                <span className={copiedURL ? "e-copy copied" : "e-copy"}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path
                                                    d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/></svg>
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="l-right">
                            <iframe src={`timer.html?time=${totalTime}&colour=${urlColour}&size=${timerSize}&background=${urlBackground}&radius=${timerBorderRadius}&font=${timerFontCode}&direction=${timerDirection}&loop=${timerLoop}`}></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <section className="m-support">
                <div className="container">
                    <div className="wrapper">
                        <a href="https://www.aitum.tv/" target="_blank">
                            <video src={'/aitum.webm'} playsInline autoPlay muted loop/>
                        </a>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default App;
